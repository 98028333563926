<template>
  <div class="k-9sptci">
    <!-- 查询数据 -->
    <div slot class="clearfix">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="标题">
          <el-input
            placeholder="请选择标题"
            v-model="searchForm.match_news_title"
            @keyup.enter.native="handleQuery()"
          />
        </el-form-item>
        <el-form-item label="类型">
          <el-select
            clearable
            v-model="searchForm.match_news_type"
            placeholder="请选择查询条件"
            @change="handleQuery()"
          >
            <el-option label="赛事公告" value="1"></el-option>
            <el-option label="赛程详情" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            @change="handleDepot()"
            v-model="searchForm.status"
            placeholder="请选择查询条件"
          >
            <el-option label="正常" :value="1"></el-option>
            <el-option label="回收站" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-button
          @click="handleQuery()"
          style="margin-left: 20px; padding: -9px 30px; font-size: 15px"
          type="primary"
          >查 询</el-button
        >
        <el-button
          @click="addActivity"
          style="margin-left: 20px; padding: -9px 30px; font-size: 15px"
          type="primary"
          >新增</el-button
        >
      </el-form>
    </div>
    <el-table :data="activityTable" style="margin-top: 20px" border>
      <el-table-column label="示意图" fixed="left" width="330">
        <template slot-scope="scope">
          <img :src="scope.row.img" alt="示意图" class="table-image" />
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in activityList"
        :key="item._id"
        :prop="item.prop"
        :type="item.type"
        :label="item.label"
        :width="item.width"
        :formatter="item.formatter"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="330">
        <template slot-scope="scope">
          <el-button @click.native.stop="handleEdit(scope.row)">编辑</el-button>

          <el-button
            style="margin-left: 10px"
            @click.native.stop="handleDelete(scope.row._id, scope.row.status)"
            >{{ scope.row.status == 1 ? "删除" : "恢复" }}</el-button
          >
          <el-button
            style="margin-left: 10px"
            @click.native.stop="handleSetBest(scope.row._id, scope.row.is_top)"
            >{{ scope.row.is_top == 1 ? "取消置顶" : "置顶" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { MessageBox } from "element-ui";
export default {
  data() {
    let self = this;
    return {
      ids: [],
      action: 0,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        match_news_type: "",
        match_news_title: "",
        status: 1,
      },
      activityTable: [],
      activityList: [
        {
          prop: "match_news_title",
          label: "标题",
        },
        {
          prop: "createTime",
          width: 180,
          label: "发布时间",
          formatter: this.timeFormat,
        },
        {
          prop: "status",
          label: "当前状态",
          width: 200,
          formatter: (row) => {
            switch (row.status) {
              case 1:
                return "正常";
              case 0:
                return "回收站";
              default:
                return "";
            }
          },
        },
        {
          prop: "match_news_type",
          label: "类型",
          width: 120,
          formatter: (row) => {
            if (row.match_news_type == 1) {
              return "公告";
            } else {
              return "新闻";
            }
          },
        },
        {
          prop: "is_top",
          label: "是否置顶",
          width: 120,
          formatter: (row) => {
            if (row.is_top == 1) {
              return "是";
            } else {
              return "否";
            }
          },
        },
      ],

      bestId: "",
      bestData: {
        best: "",
      },
      bestRules: {
        best: [
          { required: true, message: "请输入本期最佳创意", trigger: "blur" },
          {
            pattern: /^[1-9][0-9]{3,}(,([1-9][0-9]{3,}))*$/,
            message: "请输入正确的编号，多个由英文逗号分隔",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    handleClick() {
      console.log(this.searchForm.match_news_type);
    },
    searchTimeValidator(rule, value, callback) {
      if (
        this.searchForm.start &&
        this.searchForm.start != "" &&
        this.searchForm.end &&
        this.searchForm.end != ""
      ) {
        let end = new Date(this.searchForm.end);
        let start = new Date(this.searchForm.start);
        if (start > end) {
          callback(new Error("截至时间必须大于等于起始时间"));
          return;
        }
      }

      callback();
    },
    timeFormat(row, column, cellValue, index) {
      if (cellValue && cellValue.length) {
        return new Date(cellValue).format("yyyy/MM/dd hh:mm:ss");
      } else {
        return cellValue;
      }
    },
    addActivity() {
      this.removeTabByPath(`/operate/notice/add`);
      this.$router.push({
        path: "/operate/notice/add",
        query: { isAdd: "add" },
      });
    },
    handleEdit(row) {
      this.removeTabByPath(`/operate/notice/add`);
      this.$router.push({
        path: "/operate/notice/add",
        query: { _id: row._id, isAdd: "edit" },
      });
    },

    async handleSetBest(_id, is_top) {
      try {
        let is_top_title = is_top == 0 ? "是否置顶" : "是否取消置顶";
        await MessageBox.confirm(is_top_title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        let new_top = is_top == 0 ? 1 : 0;
        const { code } = await this.$http.isTop({
          _id: _id,
          is_top: new_top,
        });
        if (code === 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.handleQuery();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async handleDelete(_id, status) {
      try {
        let status_title = status == 1 ? "是否删除" : "是否恢复";
        await MessageBox.confirm(status_title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        let new_top = status == 0 ? 1 : 0;
        const { code } = await this.$http.isTop({
          _id: _id,
          status: new_top,
        });
        if (code === 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.handleQuery();
        }
      } catch (err) {
        console.log(err);
      }
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.newsList({
        ...this.searchForm,
        pageNum,
      });
      if (errorCode != "0000") return;
      this.activityTable = data.data;
      this.pager = data.pager;
    },
    async changeBest() {
      this.$refs.bestForm.validate(async (valid) => {
        if (valid) {
          let { data, errorCode } = await this.$http.setActivityBestSubmission({
            _id: this.bestId,
            bestStr: this.bestData.best,
          });

          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "设置成功",
          });

          let curRow = this.activityTable.find((r) => r._id == data._id);
          if (curRow) {
            curRow.best = data.best;
            this.$bus.$emit("onBestSubmisstionChanged", data);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onActivityChanged(data) {
      let current = this.activityTable.find((r) => r._id == data._id);
      if (current) {
        if (data.is_current) {
          this.activityTable.forEach((r) => {
            r.is_current = false;
          });
        }

        current.id = data.id;
        current.name = data.name;
        current.createTime = data.createTime;
        current.public_end = data.public_end;
        current.is_draft = data.is_draft;
        current.best = data.best;
        current.status = data.status;
        current.is_current = data.is_current;
      } else {
        this.pager.pageNum = 1;
        this.handleQuery();
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  watch: {
    $route: {
      handler: function (newVal, oldVal) {
        this.handleQuery();
      },
      immediate: true,
    },
  },
  // async created() {

  // },
};
</script>
<style lang="less">
.table-image {
  width: 100px;
  height: auto;
}
</style>
